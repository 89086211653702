
























































































































































































































































































































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { projectModule } from '@/store/modules/project';
import { priceProject } from '@/filters/price-project';
import { typeProject } from '@/filters/type-project';
import { projectPhase } from '@/filters/project-phase';
import { banks } from '@/filters/banks';
import { serviceProject } from '@/filters/service-project';
import TableModel from '@/components/Project/TableModel.vue';
import SlideBanks from '@/components/SlideBanks.vue';
import { appointmentModule } from '@/store/modules/appointment';
import ReadMore from '@/components/ReadMore.vue';
import { savedSearchModule } from '@/store/modules/savedSearch';
import { searchModule } from '@/store/modules/search';
import moment from 'moment';
import { Carousel, Slide } from 'vue-carousel';
import VideoEmbed from 'v-video-embed/src/embed.vue';
import Fullscreen from 'vue-fullscreen/src/component.vue';
import ResizeMixin from '@/mixins/size';

@Component({
    components: {
        TableModel,
        RegisterAppointment: () =>
            import('@/components/DialogAppointment/RegisterAppointment.vue'),
        ReadMore,
        Carousel,
        Slide,
        VideoEmbed,
        SlideBanks,
        Fullscreen
    },
    filters: { priceProject, banks, typeProject, projectPhase }
})
export default class Project extends Mixins(ResizeMixin) {
    mobile = true;
    resize() {
        this.mobile = window.innerWidth <= 992;
    }

    get project(): any {
        return projectModule.project.data;
    }

    get unselect() {
        return searchModule.searchSelected
            ? searchModule.searchSelected.unselect
            : { projectName: false, address: false };
    }

    get projectDescription() {
        if (this.project?.description && this.unselect.projectName) {
            const regex = new RegExp(
                `(${this.project.name}|${this.project.tradename})`,
                'gi'
            );
            return this.project?.description.replace(regex, '');
        } else {
            return this.project?.description;
        }
    }

    get isCustomer() {
        return !!searchModule.searchSelected;
    }

    get date() {
        if (!this.project) return '';
        return moment(this.project.date_delivery, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            .locale('es')
            .format('LL');
    }

    get dialogAppointment() {
        return appointmentModule.dialog;
    }

    get services() {
        return serviceProject(this.project.services);
    }

    actives: number[] = [1, 2, 3, 4, 5];
    activeName = '0';

    getProject() {
        projectModule.getProject({
            id: parseInt(this.$route.params.id),
            idProject: this.$route.params.idProject,
            query: this.$route.query
        });
        appointmentModule.closeOrOpenDialog(false);
    }

    async mounted() {
        await projectModule.getProject({
            id: parseInt(this.$route.params.id),
            idProject: this.$route.params.idProject,
            query: this.$route.query
        });
        this.$socket.on('project_change', this.handleProjectChange);
        this.$socket.on('typology_change', this.handleTypologyChange);
        this.resize();
    }

    handleProjectChange(data?: { _id: string; condition_pago: number }) {
        if (data && data._id === this.project._id) {
            appointmentModule.updatePaymentCondition(data.condition_pago);
            projectModule.setProject({
                condition_pago: data.condition_pago
            });
        }
    }

    handleTypologyChange(data?: { _id: string; comisionTipologia: number }) {
        if (data) {
            appointmentModule.updateCommission(data.comisionTipologia);
            projectModule.setProject({
                tipologias: projectModule.project.data?.tipologias.map(
                    (typology: any) => {
                        if (typology._id === data._id) {
                            return {
                                ...typology,
                                comisionTipologia: data.comisionTipologia
                            };
                        }
                        return typology;
                    }
                )
            });
        }
    }

    beforeDestroy() {
        this.$socket.off('project_change', this.handleProjectChange);
        this.$socket.off('typology_change', this.handleTypologyChange);
    }

    get link() {
        if (savedSearchModule.savedSearchSelected) {
            return `/saved-search/${savedSearchModule.savedSearchSelected._id}`;
        } else if (searchModule.searchSelected) {
            return `/email-search/${searchModule.searchSelected._id}`;
        }
        return '/search';
    }

    get loading() {
        if (!projectModule.project) return false;
        return (
            typeof this.project === 'object' && !projectModule.project.loading
        );
    }

    get models() {
        if (!this.project) return [];
        if (!this.project.tipologias) return [];
        if (this.project.type === 1) {
            return this.project.tipologias
                .map((typology: any) => {
                    if (typology.nombreTipologia === 'No definido') {
                        typology.nombreTipologia = '';
                    }
                    return typology;
                })
                .reduce((array: any[], element: any) => {
                    const index = array.findIndex(
                        ({ name }) => name === element.nombreTipologia
                    );
                    if (index < 0) {
                        array.push({
                            name: element.nombreTipologia,
                            values: [element]
                        });
                    } else {
                        array[index].values.push(element);
                    }
                    return array;
                }, [])
                .sort((a: any, b: any) => {
                    return a.name.length - b.name.length;
                });
        } else {
            return [
                {
                    name: this.project.tipoProyecto,
                    values: this.project.tipologias
                }
            ];
        }
    }

    $refs!: {
        fullscreen: any;
    };

    fullscreen: any = false;

    fullscreenChange(fullscreen: any) {
        this.fullscreen = fullscreen;
    }

    toggle() {
        this.$refs.fullscreen.toggle();
    }
}
