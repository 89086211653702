export function banks(value?: string): string | undefined {
    if (!value) return '';

    return value
        .split(',')
        .filter(element => element !== '0')
        .reduce((array: string[], element) => {
            element = element.replace('Banco ', '');
            array.push(element);
            return array;
        }, [])
        .join(', ');
}
