import { VuexModule, Module, Action } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';
import { SearchMail } from '@/interfaces/search-mail';

@Module({ generateMutationSetters: true })
export class SearchModule extends VuexModule {
    searchSelected: SearchMail | null = null;
    dialog = false;

    @Action
    async closeOrOpenDialog(val: boolean) {
        this.dialog = val;
    }

    @Action
    async getById(id: string) {
        const { data } = await Axios.get(`email-busqueda/${id}`);
        this.searchSelected = data;
    }

    @Action
    async addOrRemoveFavorite(id: string) {
        if (this.searchSelected) {
            const index = this.searchSelected.customer.favorites.findIndex(
                favorite => favorite === id
            );
            const body = {
                esClientePrincipal: this.searchSelected.customer.type === 'principal',
                clienteId: this.searchSelected.customerId,
                agenteId: this.searchSelected.agenteId,
                projectId: id
            };
            if (index >= 0) {
                await Axios.post('citas/quitar-de-favorito', body);
                this.searchSelected.customer.favorites.splice(index, 1);
            } else {
                await Axios.post('citas/agregar-a-favorito', body);
                this.searchSelected.customer.favorites.push(id);
            }
        }
    }
}
export const searchModule = new SearchModule({
    store,
    name: 'search'
});
