var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{staticClass:"table-project",staticStyle:{"width":"100%"},attrs:{"data":_vm.models,"max-height":_vm.type > 0 ? 'auto' : _vm.maxHeight,"cell-class-name":_vm.cellClassName,"row-class-name":"row-table-project","header-cell-class-name":_vm.headerCellClassName,"header-row-class-name":"row-table-project"}},[_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.type !== 0)?_c('p',{staticClass:"m-0 d-lg-none text-left"},[(_vm.project.type_project === 1)?[_vm._v(" "+_vm._s(row.nombreTipologia)+" "+_vm._s(row.name)+" ")]:[_vm._v(_vm._s(row.name))]],2):_vm._e(),_c('div',{staticClass:"\n                    position-relative\n                    d-flex\n                    align-items-start\n                    justify-content-center\n                    border\n                    my-2\n                "},[_c('div',{staticClass:"demo-image__preview"},[_c('el-image',{key:row._id + 'modelspreview',staticClass:"imageHover",class:{ imageHover__project: _vm.type !== 0 },style:(_vm.sizeImage || {
                                width: _vm.type === 0 ? '60px' : '100px',
                                height: _vm.type === 0 ? '60px' : '75px'
                            }),attrs:{"src":row.image_xmedium,"preview-src-list":row.image_big}})],1),(_vm.type !== 0 && !_vm.isCustomer)?_c('span',{staticClass:"\n                        bg-primary\n                        text-white\n                        position-absolute\n                        f-11\n                        comision-tag\n                        px-1\n                        d-lg-none\n                    "},[_vm._v("Com. "+_vm._s(row.comisionTipologia)+" %")]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Modelo","class-name":_vm.type !== 0 ? 'px-2 w-150' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var row = ref.row;
return [_c('p',{staticClass:"m-0 d-none d-lg-block text-left pl-2"},[(_vm.project.type_project === 1)?[_vm._v(" "+_vm._s(row.nombreTipologia)+" "+_vm._s(row.name)+" ")]:[_vm._v(_vm._s(row.name))]],2),(_vm.type === 1)?_c('p',{staticClass:"m-0 d-none d-lg-block text-left pl-2 break-word"},[_vm._v(" "+_vm._s(_vm._f("floorSingular")(row.floors))+": "+_vm._s(_vm._f("floors")(row.floors))+" ")]):_vm._e(),_c('ul',{staticClass:"list-unstyled pl-2 pt-1 m-0 d-lg-none"},[(_vm.type === 0)?_c('li',{staticClass:"text-left"},[_c('strong',[_vm._v("Departamento:")]),_vm._v(" "+_vm._s(row.name)+" ")]):_c('li',{staticClass:"d-sm-none text-left pb-1"},[(!_vm.isCustomer)?_c('el-button',{staticClass:"line-height-14",attrs:{"size":"mini","type":"warning"},on:{"click":function($event){return _vm.openModal(row)}}},[_vm._v(" Solicitar cita "),_c('br'),_vm._v(" ("+_vm._s(row.unidades)+" "+_vm._s(_vm._f("propertySingular")(row.unidades))+") ")]):_vm._e(),_c('br'),(_vm.isCustomer)?_c('el-button',{staticClass:"line-height-14",attrs:{"size":"mini","type":"success"},on:{"click":function($event){return _vm.openModal(row)}}},[_vm._v(" Solicitar "),_c('br'),_vm._v(" información/cita "),_c('br'),_vm._v(" "),_c('el-image',{style:({ width: '16px', height: '16px'}),attrs:{"src":"/img/icons/whatsapp.png"}})],1):_vm._e()],1),_c('li',{staticClass:"text-left",class:{ 'text-primary font-weight-bold': _vm.type !== 0 }},[_c('p',{staticClass:"my-0"},[(_vm.type === 0)?_c('strong',[_vm._v("Precio desde:")]):[_vm._v("Desde S/.")],_vm._v(" "+_vm._s(_vm._f("priceProject")(row.minPricePEN))+" ")],2),_c('p',{staticClass:"my-0 text-dark"},[_vm._v(" (Ref. $ "+_vm._s(_vm._f("priceProject")(row.minPriceUSD))+") ")])]),(_vm.project.type_project === 1)?_c('div',{staticClass:"d-flex",class:{
                        'flex-column': _vm.type === 0,
                        'flex-row': _vm.type !== 0
                    }},[_c('li',{staticClass:"text-left"},[(_vm.type === 0)?_c('strong',[_vm._v("Dormitorios:")]):_vm._e(),_vm._v(" "+_vm._s(row.minDormitorios)+" "),(_vm.type !== 0)?[_vm._v("dorm-")]:_vm._e()],2),_c('li',{staticClass:"text-left"},[(_vm.type === 0)?_c('strong',[_vm._v("Baños:")]):_vm._e(),_vm._v(" "+_vm._s(row.minBanios)+" "),(_vm.type !== 0)?[_vm._v("baños")]:_vm._e()],2)]):_vm._e(),_c('li',{staticClass:"text-left"},[(_vm.type === 0)?_c('strong',[_vm._v("Área m2:")]):_vm._e(),_vm._v(" "+_vm._s(row.minMetters)+" "),(_vm.type !== 0)?[_vm._v("m2")]:_vm._e()],2),_c('li',{staticClass:"text-left"},[_vm._v("Pisos: "+_vm._s(_vm._f("floors")(row.floors)))])])]}}])}),(_vm.project.type_project === 1)?[_c('el-table-column',{attrs:{"prop":"minDormitorios","class-name":"d-none d-lg-table-cell w-51","label":"Dorm."}}),_c('el-table-column',{attrs:{"prop":"minBanios","class-name":"d-none d-lg-table-cell w-71 px-2","label":"Baños"}})]:_vm._e(),([1, 2].includes(_vm.project.type_project) && _vm.type === 0)?_c('el-table-column',{attrs:{"prop":"floors","label":"Pisos","class-name":"d-none d-lg-table-cell"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("floors")(row.floors))+" ")]}}],null,false,1832025382)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"minMetters","class-name":"d-none d-lg-table-cell","label":"A.Total (m2)"}}),_c('el-table-column',{attrs:{"label-class-name":_vm.type === 0 ? 'text-nowrap' : '',"class-name":"d-none d-lg-table-cell"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('p',{staticClass:"my-0"},[_vm._v("S/. "+_vm._s(_vm._f("priceProject")(row.minPricePEN)))]),_c('p',{staticClass:"my-0"},[_vm._v(" (Ref. $ "+_vm._s(_vm._f("priceProject")(row.minPriceUSD))+") ")])]}}])},[_c('template',{slot:"header"},[_vm._v(" Precio "),(_vm.type === 1)?[_c('br')]:_vm._e(),_vm._v(" desde ")],2)],2),(!_vm.isCustomer)?_c('el-table-column',{attrs:{"class-name":"d-none d-lg-table-cell w-65"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.comisionTipologia)+" % ")]}}],null,false,97382855)},[_c('template',{slot:"header"},[_c('p',{staticClass:"my-0"},[_vm._v("Comisión")]),_c('p',{staticClass:"my-0"},[_vm._v("(inc. IGV)")])])],2):_vm._e(),_c('el-table-column',{attrs:{"class-name":"d-none d-sm-table-cell w-121"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [(!_vm.isCustomer)?_c('el-button',{staticClass:"white-space-normal px-2 line-height-14",attrs:{"size":"mini","type":"warning"},on:{"click":function($event){return _vm.openModal(row)}}},[_vm._v(" Solicitar cita "),_c('br'),_vm._v(" ("+_vm._s(row.unidades)+" "+_vm._s(_vm._f("propertySingular")(row.unidades))+") ")]):_vm._e(),_c('br'),(_vm.isCustomer)?_c('el-button',{staticClass:"white-space-normal px-2 line-height-14",attrs:{"size":"mini","type":"success"},on:{"click":function($event){return _vm.openModal(row)}}},[_vm._v(" Solicitar"),_c('br'),_vm._v("información/cita"),_c('el-image',{style:({ width: '16px', height: '16px'}),attrs:{"src":"/img/icons/whatsapp.png"}})],1):_vm._e()]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }