











































import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { Carousel, Slide } from 'vue-carousel';

@Component({
    components: { Carousel, Slide }
})
export default class SideBanks extends Vue {
    @Prop({ required: true }) readonly banks!: string;

    get financeBank() {
        if (!this.banks) return [];
        let banks = this.banks.split(',');
        banks = banks.filter((bank: string) => {
            return (
                bank !== '0' &&
                bank !== 'FOVIPOL' &&
                bank !== 'Ninguno' &&
                bank !== 'Financiamiento Propio'
            );
        });
        return banks;
    }
    imageBank(bank: string) {
        const bankImage: any = {
            desktop: '',
            movil: '',
            class: ''
        };
        switch (bank) {
            case 'Banco BCP':
                bankImage.desktop += 'bcp-1.jpg';
                bankImage.movil += 'bcp-movil.png';
                bankImage.class = 'bcp';
                break;
            case 'Banco BBVA':
                bankImage.desktop += 'bbva-1.jpg';
                bankImage.movil += 'bbva-movil.png';
                bankImage.class = 'bbva';
                break;
            case 'Banco INTERBANK':
                bankImage.desktop += 'interbank-1.jpg';
                bankImage.movil += 'interbank-movil.png';
                bankImage.class = 'interbank';
                break;
            case 'Banco SCOTIABANK':
                bankImage.desktop += 'scotiabank-1.jpg';
                bankImage.movil += 'scotiabank-movil.png';
                bankImage.class = 'scotiabank';
                break;
            case 'Banco BANBIF':
                bankImage.desktop += 'banbif-1.jpg';
                bankImage.movil += 'banbif-movil.png';
                bankImage.class = 'banbif';
                break;
            default:
                break;
        }
        return bankImage;
    }
}
