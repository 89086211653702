





















































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { appointmentModule } from '@/store/modules/appointment';
import { priceProject } from '@/filters/price-project';
import { floors } from '@/filters/floors';
import { propertySingular } from '@/filters/property-singular';
import { floorSingular } from '@/filters/floor-singular';
import { searchModule } from '@/store/modules/search';
import { typeProject } from '@/filters/type-project';
import { projectPhase } from '@/filters/project-phase';

@Component({
    filters: { priceProject, floors, propertySingular, floorSingular, typeProject, projectPhase }
})
export default class TableModel extends Vue {
    @Prop({ default: 0 }) readonly type!: number;
    @Prop({ required: true }) readonly models!: any[];
    @Prop({ required: true }) readonly project!: any;
    @Prop({ required: false }) readonly sizeImage!: object;
    @Prop({ type: String }) readonly headerCellClassName!: string;
    @Prop({ type: Number, default: 346 }) readonly maxHeight!: number;
    @Prop({ type: String, default: 'text-center f-12 py-0' })
    readonly cellClassName!: string;

    get isCustomer() {
        return !!searchModule.searchSelected;
    }

    openModal(row: any) {
        if (this.isCustomer) {
            this.sendWhatsapp(row);
        } else {
            appointmentModule.closeOrOpenDialog(true);
            appointmentModule.setModel({ ...row, project: this.project });
        }
    }

    sendWhatsapp(row: any) {
        const sinOnly = {
            bathrooms : searchModule.searchSelected?.filter?.bathrooms,
            bedrooms : searchModule.searchSelected?.filter?.bedrooms,
            commission : searchModule.searchSelected?.filter?.commission,
            condition : searchModule.searchSelected?.filter?.condition,
            departamento : searchModule.searchSelected?.filter?.departamento,
            discarded : searchModule.searchSelected?.filter?.discarded,
            distrito : searchModule.searchSelected?.filter?.distrito,
            financeBanks : searchModule.searchSelected?.filter?.financeBanks,
            locations : searchModule.searchSelected?.filter?.locations,
            meters : searchModule.searchSelected?.filter?.meters,
            orderBy : searchModule.searchSelected?.filter?.orderBy,
            orientation : searchModule.searchSelected?.filter?.orientation,
            page : searchModule.searchSelected?.filter?.page,
            price : searchModule.searchSelected?.filter?.price,
            projectPhases : searchModule.searchSelected?.filter?.projectPhases,
            projectTypes : searchModule.searchSelected?.filter?.projectTypes,
            provincia : searchModule.searchSelected?.filter?.provincia,
            typologyTypes : searchModule.searchSelected?.filter?.typologyTypes,
            urbanization_id : searchModule.searchSelected?.filter?.urbanization_id,
        }
        const routeData = this.$router.resolve({
            path: `/email-search/project/${this.project.project_id}/${searchModule.searchSelected?._id}`,
            query: sinOnly as any
        });
        const phone = `51${searchModule.searchSelected?.agent?.celular}`;
        const message = encodeURIComponent(
            `Hola agente ${searchModule.searchSelected?.agent?.fullname}, me gustaría agendar una cita para proyecto con las siguientes características:\n * Tipo: ${typeProject(row.type_project)}\n * Metros: ${row.minMetters} m2\n * Precio desde: S/. ${row.minPricePEN}\n * Estado de proyecto: ${projectPhase(this.project.project_phase)}\n * Distrito: ${this.project.district}\n * Link del proyecto ${window.location.origin}${routeData.href}`
        );
        const url_whatsap = `https://wa.me/${phone}?text=${message}`;
        const deviceAgent = navigator.userAgent;
        const ios = deviceAgent.toLowerCase().match(/(iphone|ipod|ipad)/);
        if (ios) {
            window.location.href = url_whatsap;
        } else {
            window.open(url_whatsap, '_blank');
        }
    }
}
