


























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ReadMore extends Vue {
    @Prop({ required: true, type: String }) readonly text!: string;
    @Prop({ type: Number, default: 200 }) readonly maxChars!: number;
    active = false;

    get isLongText() {
        return this.text.length > this.maxChars;
    }
}
