export function floors(value?: number[]): string | undefined {
    if (!value) return '';

    const elements = value
        .filter((v, i, a) => a.indexOf(v) === i)
        .filter(v => v > 0)
        .sort((a, b) => a - b);

    if (elements.length > 2 && elements.length < 6) {
        return elements.join(', ');
    } else if (elements.length === 2) {
        return `${elements[0]} y ${elements[elements.length - 1]}`;
    } else if (elements.length > 5) {
        return `Entre ${elements[0]} al ${elements[elements.length - 1]}`;
    } else if (!elements[0]) {
        return '0';
    } else {
        return elements[0].toString();
    }
}
